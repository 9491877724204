import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../api/auth';
import {jwtDecode} from 'jwt-decode'; // Correct import statement

const Navbar = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('accessToken');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    if (isLoggedIn) {
      const token = localStorage.getItem('accessToken');
      const decodedToken = jwtDecode(token);
      setUserEmail(decodedToken.email);
    }
  }, [isLoggedIn]);

  const handleLogout = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      await logout(token);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">MyApp</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            {isLoggedIn && (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">Dashboard</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/customers">Customers</Link>
                </li>
                <li className="nav-item dropdown">
                  <button className="btn btn-link nav-link dropdown-toggle" id="reportDropdown"   data-bs-toggle="dropdown" aria-expanded="false">
                    Report
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="reportDropdown">
                    <li><Link className="dropdown-item" to="/report1">Report 1</Link></li>
                    <li><Link className="dropdown-item" to="/report2">Report 2</Link></li>
                    <li><Link className="dropdown-item" to="/report3">Report 3</Link></li>
                    <li><Link className="dropdown-item" to="/report4">Report 4</Link></li>
                    <li><Link className="dropdown-item" to="/report5">Report 5</Link></li>
                    <li><Link className="dropdown-item" to="/report6">Report 6</Link></li>
                    <li><Link className="dropdown-item" to="/report7">Report 7</Link></li>
                  </ul>
                </li>
              </>
            )}
          </ul>
          <ul className="navbar-nav ms-auto">
            {!isLoggedIn ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">Login</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/register">Register</Link>
                </li>
              </>
            ) : (
              <li className="nav-item dropdown">
                <button className="btn btn-link nav-link dropdown-toggle" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fas fa-user"></i> {userEmail}
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                  <li>
                    <Link className="dropdown-item" to="/change-password">Change Password</Link>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
