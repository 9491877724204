// src/components/Dashboard/Dashboard.js
import React from 'react';

const Dashboard = () => {
  return (
    <div className="mt-5">
      <h2>Dashboard</h2>
      <p>Welcome to the dashboard!</p>
    </div>
  );
};

export default Dashboard;
