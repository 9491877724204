// src/pages/Home.js
import React from 'react';

const Home = () => {
  return (
    <div className="mt-5">
      <h2>Home</h2>
      <p>Welcome to the home page!</p>
    </div>
  );
};

export default Home;
